.tox.tox-tinymce {
  z-index: 0;
}

svg.star-svg {
  display: inline;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
 /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  
}
.player-wrapper-content {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 auto;
 /* Player ratio: 100 / (1280 / 720) */
}
.react-player-content {
  position: absolute;
  top: 0;
  left: 0;
 
}